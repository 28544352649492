import React from 'react';

import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Pagination } from '@hbf/dsl/core';

import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';

import { changePage } from '../../actions';
import { SEARCH_LAYOUT_LISTINGS_CONTAINER } from '../../constants';
import { getMapViewState, getPagerProps } from '../../selectors';
import { MapViewState } from '../../types';

const useStyles = makeStyles()(() => ({
  container: {
    padding: '40px 0',
  },
}));

const PaginationContainer: React.FC = () => {
  const mapViewState = useSelector(getMapViewState);
  const pagerProps = useSelector(getPagerProps);

  const dispatch = useDispatch();

  const pageCount = Math.ceil(
    pagerProps.pager.total / pagerProps.pager.quantity,
  );

  const onPageChange = React.useCallback(
    (_: React.ChangeEvent<unknown>, value: number) => {
      dispatch(changePage({ num: value }));

      if (mapViewState === MapViewState.halfMap) {
        document?.getElementById(SEARCH_LAYOUT_LISTINGS_CONTAINER)?.scrollTo({
          top: 0,
        });
        return;
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [dispatch, mapViewState],
  );

  const { classes } = useStyles();

  if (pageCount < 2) return null;

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Pagination
        count={pageCount}
        page={pagerProps.pagerData.pageNum}
        onChange={onPageChange}
      />
    </Grid>
  );
};

const PaginationContainerWrapper: React.FC = () => (
  <RebrandThemeProvider>
    <PaginationContainer />
  </RebrandThemeProvider>
);

export { PaginationContainerWrapper as PaginationContainer };
