import flowRight from 'lodash-es/flowRight';
import mapValues from 'lodash-es/mapValues';
import property from 'lodash-es/property';
import sortBy from 'lodash-es/sortBy';
import _values from 'lodash-es/values';

export interface DistributionValue {
  value: number;
  range: number;
}

const reformatDistribution = flowRight(
  (values: DistributionValue[]) => {
    if (values.length < 2) {
      return values;
    }

    const edge = values.pop() as DistributionValue;
    const last = values.pop() as DistributionValue;

    return [...values, { ...last, value: last.value + edge.value }];
  },
  sortBy(property('range')),
  _values,
  (values: DistributionValue[]) =>
    mapValues(values, (value, range) => ({
      range: parseInt(range, 10),
      value,
    })),
);

export { reformatDistribution };
