import {
  getSearchParams,
  getBounds,
  getCityCanonical,
  getCountryCode,
} from '../selectors';
import {
  getInitialLatitude,
  getInitialLongitude,
  getRadius,
} from '../selectors/mapSelectors';

import { updateSearchParams, setPreviousParams } from './actions';
import { loadSearchResults } from './loadSearchResults';
import { saveSearch } from './saveSearch';

const load = (reqParams, cityInfo, rates) => (dispatch, getState) => {
  dispatch(updateSearchParams(reqParams));
  const shouldUseMap = Boolean(
    reqParams.lLng && reqParams.rLng && reqParams.tLat && reqParams.bLat,
  );
  const state = getState();
  const searchParams = getSearchParams(state);

  const mapBounds = getBounds(state);

  const initialLatitude = getInitialLatitude(state);
  const initialLongitude = getInitialLongitude(state);
  const cityCanonical = getCityCanonical(state);
  const searchMapRadius = getRadius(state);
  const countryCode = getCountryCode(state);

  const searchParamsWithMapBounds = {
    ...searchParams,
    ...(shouldUseMap && {
      ...mapBounds,
    }),
  };

  return dispatch(
    loadSearchResults({
      reqParams: searchParamsWithMapBounds,
      localizedKind: reqParams.localizedKind,
      priceAverage: reqParams.priceAverage,
      cityInfo: cityInfo,
      currencyEuroRates: rates,
      initialLatitude: initialLatitude,
      initialLongitude: initialLongitude,
      cityCanonical: cityCanonical,
      countryCode: countryCode,
      radius: searchMapRadius,
    }),
  )
    .then(() => {
      dispatch(saveSearch());
      dispatch(setPreviousParams(searchParamsWithMapBounds));
    })
    .catch(error => Promise.reject(error));
};

export { load };
