// @ts-nocheck
import { handleActions } from 'redux-actions';

import { Actions } from '../constants';

const initialState = { organic: 0, partner: 0, isExhaustive: true };

const listingsSummaryReducer = handleActions(
  {
    [Actions.LOAD_DONE]: (_, { payload: { listingsSummary } }) =>
      listingsSummary,
  },
  initialState,
);
export { listingsSummaryReducer };
