import { DEFAULT_SEARCH_FLEXIBILITY } from 'ha/constants/FlexibleSearchDates';
import { OCCUPATION } from 'ha/constants/Occupation';

import { Gender } from 'ha/types/Gender';

import {
  occupationToFilterValues,
  genderToFilterValues,
  getValidMoveInDate,
} from './helpers';
import {
  MatchmakingFilterProperties,
  SavedMatchmakingProperties,
} from './types';

export interface BuildMatchmakingFiltersProps {
  savedFilters: SavedMatchmakingProperties | null;
}

export const buildMatchmakingFilters = ({
  savedFilters,
}: BuildMatchmakingFiltersProps): MatchmakingFilterProperties | null => {
  if (!savedFilters) return null;

  const gender = savedFilters?.gender;
  const occupation = savedFilters?.occupation;

  return {
    gender: genderToFilterValues(gender as Gender),
    suitableFor: occupationToFilterValues(occupation as OCCUPATION),
    startDate: getValidMoveInDate(savedFilters?.moveInDate),
    flexDays: DEFAULT_SEARCH_FLEXIBILITY.Home as unknown as string,
  };
};
